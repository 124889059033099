/*=========================================================================================
  File Name: moduleAccMutations.js
  Description: Acc Module Mutations

==========================================================================================*/

export default {
  SET_AIFILES(state, aiFiles) {
    state.aiFiles = aiFiles;
  },
  SET_RESTRICT_REPORT_DOWNLOAD(state, data) {
    state.restrictReportDownload = data;
  },
  REMOVE_AIFILE_BY_ID(state, id) {
    state.aiFiles = state.aiFiles.filter((file) => {
      return file._id !== id;
    });
  },
  SET_ACCS(state, accounts) {
    state.accounts = accounts;
  },
  /**{
  id: 0,
  name: "",
  plans: { chatbot: { id: 0, price: 0, planParameters: {}, plan: { id: 0, name: '', planParameters: {}, price: 0 }, trialDays: 0 } },
} */

  SET_CURRENT_ACC_ID(state, current_acc_id) {
    state.current_acc.id = current_acc_id;
  },

  SET_CURRENT_ACC(state, current_acc) {
    if (current_acc.acc == null) return;
    let acc = current_acc.acc;

    var pAcc = {
      id: acc.id,
      name: acc.name,
      timezone: acc.timezone || "",
      companyIndustry: acc.companyIndustry,
      companyGoal: acc.companyGoal,
      createdAt: acc.createdAt,
      status: acc.status,
      whitelabel: acc.whitelabel,
      accPlan: {},
      accIsPaying: current_acc.accIsPaying,
      inQueue: acc.inQueue,
      locked: acc.locked,
      aiEnable: acc.aiEnable,
      assistantEnable: acc.assistantEnable,
      timeZone: acc.timezone,
      blockContactTab: acc.blockContactTab,
      blockContactTabGeneral: acc.blockContactTabGeneral,
      blockDownloadExcel: acc.blockDownloadExcel,
      smsDispatchEnable: acc.smsDispatchEnable,
      automationEnable: acc.automationEnable,
      smsCredit: acc.smsCredit,
    };
    var plan = current_acc.plan;
    var accPlan = current_acc.accPlan;

    var trialDays = 0;

    if (accPlan.trialStartDate != null) {
      var today = new Date();
      var endTrial = new Date(accPlan.trialEndDate);
      var diffTime = Math.round((endTrial - today) / (1000 * 60 * 60 * 24));
      if (diffTime > 0) trialDays = diffTime;
    }

    const ParsedPlan = {
      trial: plan.trialDays,
      superlogicaId: current_acc.accFinancial
        ? current_acc.accFinancial.superlogicaIdCliente
        : null,
      id: accPlan._id,
      price: accPlan.price,
      planParameters: accPlan.planParameters,
      paymentStatus: accPlan.paymentStatus,
      period: accPlan.period,
      endDate: accPlan.endDate,
      startTrialDate: accPlan.trialStartDate,
      endTrialDate: accPlan.trialEndDate,
      plan: {
        id: plan._id,
        name: plan.title,
        planParameters: plan.planParameters,
        price: plan.price,
        idSuperlogica: plan.idSuperlogica,
      },
      trialDays: trialDays,
      isBonus: accPlan.isBonus || false,
    };

    pAcc.accPlan = ParsedPlan;

    state.current_acc = pAcc;
  },

  SET_CURRENT_ACC_USERS(state, current_acc_users) {
    state.current_acc_users = current_acc_users;
  },
  ADD_ACC_USER(state, acc_user) {
    state.current_acc_users.push(acc_user);
  },
};
