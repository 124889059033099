/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
==========================================================================================*/

import auth from "@/auth/authService";
import store from "@/store/store";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

function hideLoading(showLoading) {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    if (showLoading) appLoading.style.display = "block";
    var appSubLoading = document.getElementById("loading-bg-logo");
    if (appSubLoading) appSubLoading.style.display = "none";
    appSubLoading = document.getElementById("general-loading-bg");
    if (appSubLoading) appSubLoading.style.display = "none";
  }
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "/",
          redirect: () => ({
            name: "dashboard",
            query: router.query,
          }),
        },
        {
          path: "/legacy-dashboard",
          name: "legacy-dashboard",
          component: () => import("./views/DashboardAnalytics.vue"),
          meta: {
            pageTitle: "Dashboard",
            rule: "operator",
            authRequired: true,
            showLoading: true,
            compliantOnly: true,
          },
        },
        {
          path: "/new-dashboard",
          redirect: "/dashboard",
          meta: {
            pageTitle: "Dashboard",
            rule: "operator",
            authRequired: true,
            showLoading: true,
            compliantOnly: true,
          },
        },
        {
          path: "/dashboard",
          component: () => import("./views/dashboard-v2"),
          children: [
            {
              path: "",
              name: "dashboard",
              component: () => import("./views/dashboard-v2/home/Home.vue"),
              meta: {
                pageTitle: "Dashboard",
                rule: "operator",
                authRequired: true,
                showLoading: true,
                compliantOnly: true,
                parent: "dashboard",
              },
            },
            {
              path: "marketing",
              component: () =>
                import("./views/dashboard-v2/reports/Reports.vue"),
              meta: {
                pageTitle: "Marketing Reports",
                rule: "manager",
                authRequired: true,
                showLoading: true,
                compliantOnly: true,
                parent: "dashboard",
              },
            },
            {
              path: "performance",
              component: () =>
                import("./views/dashboard-v2/performance/Performance.vue"),
              meta: {
                pageTitle: "Performance",
                rule: "manager",
                authRequired: true,
                showLoading: true,
                compliantOnly: true,
                parent: "dashboard",
              },
            },
          ],
        },
        {
          path: "/appstore",
          name: "appstore",
          component: () => import("./views/appstore/AppStore.vue"),
          meta: {
            pageTitle: "AppStore",
            rule: "admin",
            authRequired: true,
            showLoading: true,
            compliantOnly: true,
          },
        },
        {
          path: "/appstore/history",
          name: "appstore_history",
          component: () => import("./views/appstore/AppsLogs.vue"),
          meta: {
            pageTitle: "AppStoreHistory",
            rule: "admin",
            authRequired: true,
            showLoading: true,
            compliantOnly: true,
            parent: "appstore",
          },
        },
        {
          path: "/acc",
          name: "acc",
          component: () => import("@/views/apps/acc/AccView.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Account", active: true },
            ],
            pageTitle: "Account",
            rule: "admin",
            authRequired: true,
            showLoading: true,
          },
        },
        {
          path: "/acc/users",
          name: "acc-users",
          component: () => import("@/views/apps/acc/user-list/UserList.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Account", url: "/acc" },
              { title: "UserList", active: true },
            ],
            pageTitle: "UserList",
            rule: "admin",
            authRequired: true,
            showLoading: true,
          },
        },
        {
          path: "/apps/leads",
          name: "leads",
          component: () => import("./views/apps/leads/Leads.vue"),
          meta: {
            rule: "operator",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            pageTitle: "Leads",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Leads", active: true },
            ],
            showLoading: true,
          },
        },
        {
          path: "/apps/chatbots",
          name: "chatbots",
          component: () => import("./views/apps/chatbots/ChatBots.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            pageTitle: "Chatbots",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Chatbots", active: true },
            ],
            showLoading: true,
          },
        },
        {
          path: "/apps/chatbot/create/:chatbotType/:template_id?",
          name: "chatbot-create",
          component: () => import("@/views/apps/chatbots/ChatbotSettings.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            pageTitle: "ChatbotCreate",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Chatbot", url: "/apps/chatbots" },
              { title: "Create", active: true },
            ],
            showLoading: true,
          },
        },
        {
          path: "/apps/chatbot-ai/:chatbot_id?",
          name: "chatbot-ai",
          component: () =>
            import("@/views/apps/chatbots/ChatbotAIManagement.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            pageTitle: "ChatbotCreate",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Chatbot", url: "/apps/chatbots" },
              { title: "Create", active: true },
            ],
            showLoading: true,
          },
        },
        {
          path: "/faq/chatbot-ia",
          name: "faq",
          component: () =>
            import("@/views/apps/chatbots/faq-ia/ChatbotAIFaq.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/faq/chatbot-ia/duotalk-ia",
          name: "chatbot-ia-duotalk",
          component: () =>
            import("@/views/apps/chatbots/faq-ia/ChatbotIADuotalk.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/faq/chatbot-ia/base-conhecimento",
          name: "chatbot-ia-base",
          component: () =>
            import(
              "@/views/apps/chatbots/faq-ia/ChatbotIABaseConhecimento.vue"
            ),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/faq/chatbot-ia/prompt-atendimento",
          name: "chatbot-ia-prompt",
          component: () =>
            import("@/views/apps/chatbots/faq-ia/ChatbotIAPrompt.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/faq/chatbot-ia/configuracoes-avancadas",
          name: "chatbot-ia-configuracoes",
          component: () =>
            import(
              "@/views/apps/chatbots/faq-ia/ChatbotIAConfiguracoesAvancadas.vue"
            ),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/faq/chatbot-ia/limitacoes-chatgpt",
          name: "chatbot-ia-limitacoes",
          component: () =>
            import("@/views/apps/chatbots/faq-ia/ChatbotIALimitacoes.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/faq/chatbot-ia/exemplos-prompts",
          name: "chatbot-ia-exemplos",
          component: () =>
            import("@/views/apps/chatbots/faq-ia/ChatbotIAExemplos.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/apps/chatbot/edit/:chatbot_id",
          name: "chatbot-edit",
          component: () => import("@/views/apps/chatbots/ChatbotSettings.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            pageTitle: "EditChatbot",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Chatbot", url: "/apps/chatbots" },
              { title: "Edit", active: true },
            ],
            showLoading: true,
          },
        },
        {
          path: "/apps/chatbot/edit/hybrid/:chatbot_id",
          name: "chatbot-hybrid-edit",
          component: () => import("@/views/apps/chatbots/ChatbotSettings.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            pageTitle: "EditChatbot",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Chatbot", url: "/apps/chatbots" },
              { title: "Edit", active: true },
            ],
            showLoading: true,
          },
        },
        {
          path: "/apps/chatbot/edit/flow/:chatbotType?/:chatbot_id",
          name: "chatbot-flow-edit",
          component: () => import("@/views/apps/chatbots/ChatbotSettings.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            pageTitle: "EditFlow",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Chatbot", url: "/apps/chatbots" },
              { title: "Fluxo", active: true },
            ],
            showLoading: true,
          },
        },
        {
          path: "/apps/chatbot/edit/flow/hybrid/:chatbot_id",
          name: "chatbot-flow-hybrid-edit",
          component: () => import("@/views/apps/chatbots/ChatbotSettings.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            pageTitle: "EditFlow",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Chatbot", url: "/apps/chatbots" },
              { title: "Fluxo", active: true },
            ],
            showLoading: true,
          },
        },
        {
          path: "/apps/chatbot/edit/ai/:chatbot_id",
          name: "chatbot-ai-edit",
          component: () =>
            import("@/views/apps/chatbots/ChatbotAISettings.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            pageTitle: "EditAI",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Chatbot", url: "/apps/chatbots" },
              { title: "Chatbot IA", active: true },
            ],
            showLoading: true,
          },
        },
        // {
        //   path: '/apps/chatbot/edit/hybrid/:chatbot_id',
        //   name: 'chatbot-hybrid-edit',
        //   component: () =>
        //     import('@/views/apps/chatbots/ChatbotSettings.vue'),
        //   meta: {
        //     rule: 'admin',
        //     authRequired: true,
        //     compliantOnly: true,
        //     no_scroll: true,
        //     pageTitle: 'EditHybrid',
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Chatbot', url: '/apps/chatbots' },
        //       { title: 'Chatbot Hybrid', active: true },
        //     ],
        //     showLoading: true
        //   }
        // },
        {
          path: "/apps/chatpage/edit/:chatbot_id",
          name: "chatpage-edit",
          component: () => import("@/views/apps/chatbots/ChatbotSettings.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            pageTitle: "EditChatpage",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Chatbot", url: "/apps/chatbots" },
              { title: "Edit", active: true },
            ],
            showLoading: true,
          },
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/views/apps/acc/AccSettings.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "YourProfile", active: true },
            ],
            pageTitle: "Settings",
            rule: "admin",
            authRequired: true,
            showLoading: true,
          },
          children: [
            {
              name: "auto-messages",
              path: "autoMessages",
              meta: {
                rule: "admin",
              },
            },
          ],
        },
        {
          path: "/apps/campaigns",
          name: "campaigns",
          component: () => import("./views/apps/campaigns/Campaigns.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/apps/campaigns/new",
          name: "new-campaign",
          component: () => import("./views/apps/campaigns/NewCampaign.vue"),
          meta: {
            pageTitle: "NewCampaign",
            rule: "operator",
            authRequired: true,
            showLoading: true,
            compliantOnly: true,
          },
        },
        {
          path: "/apps/campaigns/sms/new",
          name: "new-campaign-sms",
          component: () => import("./views/apps/campaigns/NewCampaignSMS.vue"),
          meta: {
            pageTitle: "NewCampaignSMS",
            rule: "operator",
            authRequired: true,
            showLoading: true,
            compliantOnly: true,
          },
        },
        {
          path: "/profile",
          name: "profile",
          component: () =>
            import("@/views/pages/user-settings/UserSettingsGeneral.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "YourProfile", active: true },
            ],
            pageTitle: "Profile",
            rule: "operator",
            authRequired: true,
            showLoading: true,
          },
        },
        {
          path: "/plans",
          name: "page-plans",
          component: () => import("@/views/pages/Plans.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Plans", active: true },
            ],
            authRequired: true,
            rule: "admin",
            showLoading: true,
          },
        },
        {
          path: "/blocked",
          name: "page-blocked",
          component: () => import("@/views/pages/Blocked.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Blocked", active: true },
            ],
            authRequired: true,
            rule: "admin",
            showLoading: true,
          },
        },
        {
          path: "/restricted",
          name: "page-restricted",
          component: () => import("@/views/pages/Restricted.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Restricted", active: true },
            ],
            authRequired: true,
            rule: "operator",
            showLoading: false,
          },
        },
        {
          path: "/plans/checkout/:plan",
          name: "page-plans-checkout",
          component: () => import("@/views/pages/Checkout.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Plans", url: "/plans" },
              { title: "Checkout", active: true },
            ],
            authRequired: true,
            rule: "admin",
            showLoading: true,
          },
        },
        {
          path: "/apps/inbox/start-conversation",
          name: "inbox-start-conversation",
          component: () => import("./views/apps/chat/StartConversation.vue"),
          meta: {
            rule: "operator",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/apps/contacts",
          name: "contacts",
          component: () => import("./views/apps/contacts/Contacts.vue"),
          meta: {
            rule: "operator",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/apps/contacts/import",
          name: "import-contacts",
          component: () => import("./views/apps/contacts/ImportContacs.vue"),
          meta: {
            rule: "operator",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/apps/automation",
          name: "automation",
          component: () => import("./views/apps/automation/AutomationList.vue"),
          meta: {
            rule: "operator",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/apps/feedback",
          name: "feedback",
          component: () => import("./views/apps/feedback/Feedback.vue"),
          meta: {
            rule: "manager",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "public",
          },
        },
      ],
    },
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "operator",
          },
        },
        {
          path: "/apps/inbox",
          name: "inbox",
          component: () => import("./views/apps/inbox/Chat.vue"),
          meta: {
            rule: "operator",
            authRequired: true,
            compliantOnly: true,
            no_scroll: true,
            showLoading: true,
          },
        },
        {
          path: "/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/logout",
          name: "page-logout",
          component: () => import("@/views/pages/Logout.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/invitation/:invatation_id",
          name: "page-invatation",
          component: () =>
            import("@/views/pages/register/RegisterUserInvitation.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/forgot-password",
          name: "page-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/acc/superlogica-login",
          name: "acc-superlogica-login",
          component: () => import("@/views/apps/acc/SuperlogicaLoginView.vue"),
          meta: {
            rule: "admin",
            authRequired: true,
            showLoading: true,
          },
        },
        {
          path: "/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "public",
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/error-404",
    },
  ],
});

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    const appSubLoading = document.getElementById("general-loading-bg");
    if (appSubLoading) appSubLoading.style.display = "none";
    appLoading.style.display = "none";
  }

  // Handle compliant-only routes
  if (to.meta.compliantOnly != null) {
    let showPayWall =
      !store.state.acc.current_acc.accPlan.isBonus &&
      !store.state.acc.current_acc.accIsPaying;
    if (
      (showPayWall && store.state.acc.current_acc.accPlan.endTrialDate) ||
      store.state.acc.current_acc.locked
    ) {
      const today = new Date();
      const endTrialDate = new Date(
        store.state.acc.current_acc.accPlan.endTrialDate
      );
      showPayWall = today.getTime() > endTrialDate.getTime();
    } else {
      showPayWall = false;
    }

    if (to.meta.compliantOnly && showPayWall) {
      if (to.name !== "page-blocked") {
        router.push({ name: "page-blocked" }).catch(() => {});
      }
    }
  }

  // Handle restricted operator
  if (to.meta.authRequired && store.state.chat.restrictOperator) {
    hideLoading(false);
    if (to.name !== "page-restricted") {
      router.push({ name: "page-restricted" }).catch(() => {});
    }
    hideLoading(false);
  }

  // Add `acc` to query if missing
  const acc = store.state.acc.current_acc.id;
  if (acc && !to.query.acc) {
    const query = { ...to.query, acc };
    if (to.query.acc !== acc) {
      router.replace({ path: to.path, query }).catch(() => {});
    }
  }
});

router.beforeEach(async (to, from, next) => {
  hideLoading(to.meta.showLoading);

  if (
    to.path === "/login" ||
    to.path === "/forgot-password" ||
    to.path === "/error-404" ||
    to.path === "/error-500" ||
    to.path === "/register" ||
    to.path === "/callback" ||
    to.path === "/comingsoon"
  ) {
    return next();
  }

  if (to.name === "automation") {
    const automationEnabled = localStorage.getItem("automationEnable");

    if (!automationEnabled || automationEnabled === "undefined")
      next({ name: "page-error-404" });
    else next();
  }

  if (to.meta.authRequired) {
    if (!auth.isAuthenticated()) {
      let path = to.path;
      if (to.query) {
        path +=
          "?" +
          Object.keys(to.query)
            .map(function (k) {
              return (
                encodeURIComponent(k) + "=" + encodeURIComponent(to.query[k])
              );
            })
            .join("&");
      }
      if (router.name !== "page-login")
        router.push({ name: "page-login", query: { to: path } });
    }
  }
  return next();

  // Specify the current path as the customState parameter, meaning it
  // will be returned to the application after auth
  //auth.login({ target: to.path });
});

export default router;
