// validatorMiddleware.js
import { Validator } from 'vee-validate'
import English from 'vee-validate/dist/locale/en'
import Portuguese from 'vee-validate/dist/locale/pt_BR'

// ----------------------
// 1) DEFINE CUSTOM RULE
// ----------------------
Validator.extend('unique_alias', {
  getMessage(field) {
    // Customize or localize the error message as needed
    return `The alias provided for "${field}" is already in use. Please choose a different one.`
  },
  /**
   * @param {string} value - the actual alias input value
   * @param {Array} args - rule params, e.g. ['["alias1","alias2"]']
   *   By default VeeValidate 2.x passes an array of strings after the colon,
   *   e.g. 'unique_alias:["alias1","alias2"]' => args[0] = '["alias1","alias2"]'
   */
  validate(value, args) {
    try {
      // Example usage: JSON.parse(args[0]) to get an array of existing aliases
      const existingAliases = JSON.parse(args[0] || '[]')
      // Return true if 'value' is NOT found in 'existingAliases'
      return !existingAliases.includes(value)
    } catch (e) {
      // If parsing fails, skip validation (return true)
      return true
    }
  },
})

// ------------------
// 2) LOCALIZE CONFIG
// ------------------
// Example dictionaries for custom locale handling
const dict = {
  en: { custom: { honorificPrefix: { mrs: 'Mrs', mr: 'Mr' } } },
  br: { custom: { honorificPrefix: { mrs: 'Sra', mr: 'Sr' } } },
}

Validator.localize('en', dict.en)
Validator.localize('br', dict.br)

// ---------------------------
// 3) MIDDLEWARE EXPORT
// ---------------------------
export default function validatorMiddleware(validator) {
  // EN (English)
  validator.localize('en', {
    messages: English.messages,
    attributes: {
      givenName: 'First name',
      familyName: 'Name',
      messageContent: 'Message',
      companyName: 'company name',
      segment: 'industry',
      otherSegment: 'industry',
    },
    custom: {
      message: () => 'Message cannot be empty',
      select: 'Select field is required',
      correct_all: 'Please correct all errors in your form',
      invalidCoupon: 'Coupon code is not valid',
      error: 'Error',
      axiosPostError: 'Oops! An error occurred and your message could not be sent.',
      contactMsgSent:
        "Your message has been successfully sent. We'll be in touch soon.",
    },
  })

  // PT (Brazilian Portuguese)
  const ObjTranslate = {
    messages: Portuguese.messages,
    attributes: {
      email: 'e-mail',
      name: 'nome',
      messageContent: 'mensagem',
      new_password: 'nova senha',
      confirm_new_password: 'confirmar nova senha',
      confirmPassword: 'confirmar senha',
      password: 'senha',
      plan: 'plano',
      company: 'empresa',
      companyName: 'nome da empresa',
      segment: 'segmento',
      otherSegment: 'segmento',
      phone: 'telefone',
    },
    custom: {
      message: 'Messagem não pode estar vazio',
      required: 'Este campo é obrigatório',
      select: 'Selecione campo é obrigatório',
      correct_all: 'Por favor, corrija todos os erros em seu formulário',
      invalidCoupon: 'Cupom inválido',
      error: 'Erro',
      axiosPostError:
        'Opa! Ocorreu um erro e sua mensagem não pôde ser enviada.',
      success: 'Obrigado',
      contactMsgSent:
        'Sua mensagem foi enviada com sucesso. Nós entraremos em contato em breve.',
    },
  }
  // Let "messages.required" reference the custom "required" text
  ObjTranslate.messages.required = ObjTranslate.custom.required

  validator.localize('pt-br', ObjTranslate)
  validator.localize('pt_BR', ObjTranslate)
}