/*=========================================================================================
  File Name: store.js
  Description: Vuex store

==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";

Vue.use(Vuex);

// import moduleTodo from './todo/moduleTodo.js'
// import moduleCalendar from './calendar/moduleCalendar.js'
import moduleAcc from "./acc/moduleAcc.js";
import moduleAuth from "./auth/moduleAuth.js";
import moduleChat from "./chat/moduleChat.js";
import moduleContact from "./contact/moduleContact.js";
import moduleHybridFlow from "./hybrid-flow/moduleHybridFlow.js";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    // todo: moduleTodo,
    // calendar: moduleCalendar,
    chat: moduleChat,
    acc: moduleAcc,
    auth: moduleAuth,
    contact: moduleContact,
    hybridFlow: moduleHybridFlow,
  },
  strict: process.env.NODE_ENV !== "production",
});
