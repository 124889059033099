export default {
  namespaced: true,
  state: {
    files: [],
  },
  getters: {},
  mutations: {
    SET_FILES(state, data) {
      state.files = data;
    },
  },
  actions: {
    setFiles({ commit }, data) {
      commit("SET_FILES", data);
    },
  },
};
