<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
========================================================================================== -->

<template>
  <div id="app" :class="vueAppClasses">
    <team-chat v-if="this.$route.name !== 'page-login'" />
    <router-view @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js";
import jwt from "@/http/requests/auth/jwt/index.js";
import validatorMiddleware from "@/middlewares/validatorMiddleware";
import { getToken } from "@/plugins/firebase";
import store from "@/store/store";
import axios from "./http/axios";
import TeamChat from "./views/apps/team-chat/TeamChat.vue";

export default {
  components: {
    TeamChat,
  },
  data() {
    return {
      vueAppClasses: [],
      count: 0,
    };
  },
  computed: {
    locale() {
      return this.$store.state.user;
    },
  },
  watch: {
    "$store.state.chat.restrictOperator"(val) {
      if (val) this.$router.push({ name: "page-restricted" }).catch(() => {});
    },
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr");
    },
    "$store.state.acc.current_acc.id"() {
      this.startUsetiful();
    },
    userLoggedin() {
      this.handleRouteChange();
    },
  },
  methods: {
    startUsetiful() {
      //Usetiful code
      let itsTrial = false;
      if (
        this.$store.state.acc.current_acc.accPlan.isBonus !== true &&
        this.$store.state.acc.current_acc.accIsPaying !== true &&
        this.$store.state.acc.current_acc.accPlan.endTrialDate
      ) {
        const today = new Date();
        const endTrialDate = new Date(
          this.$store.state.acc.current_acc.accPlan.endTrialDate
        );
        if (today.getTime() < endTrialDate.getTime()) itsTrial = true;
      }
      const el = document.getElementById("usetifulScript");
      if (el) el.remove();
      window.usetifulTags = {
        segment: this.$store.state.acc.current_acc.companyIndustry,
        language: this.$store.state.user.lang,
        role: this.$store.state.user.userRole,
        firstName: this.$store.state.user.name,
        trial: String(itsTrial),
      };

      (function (w, d, s) {
        var a = d.getElementsByTagName("head")[0];
        var r = d.createElement("script");
        r.async = 1;
        r.src = s;
        r.setAttribute("id", "usetifulScript");
        r.dataset.token = "f84767917f85f7e1b1841fbfdee7a967";
        a.appendChild(r);
      })(window, document, "https://www.usetiful.com/dist/usetiful.js");
    },
    pushRouteAccParam(acc) {
      // Check if the route already has the 'acc' query parameter
      const currentQuery = { ...this.$router.currentRoute.query };
      if (currentQuery.acc === acc || this.$router.currentRoute.path === "/") {
        return; // No need to update if the parameter is already set
      }
      // Update the route with the new 'acc' parameter
      this.$router
        .push({
          query: { ...currentQuery, acc },
        })
        .catch((err) => {
          // Handle navigation errors, such as when the route is already at the desired state
          if (err.name !== "NavigationDuplicated") {
            //console.error(err);
          }
        });
    },
    async handleRouteChange() {
      const urlParams = new URLSearchParams(window.location.search);
      const acc = urlParams.get("acc") || localStorage.getItem("activeAcc");
      if (acc) {
        await this.$store.dispatch("acc/setCurrentAccount", { acc });
        this.$acl.change(this.$store.state.user.userRole);
        this.pushRouteAccParam(acc);
      }
    },
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
        document.body.classList.add("theme-dark");
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

      // Set --vh property
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
    },
    setLocale(lang) {
      var language = (
        lang ||
        window.navigator.userLanguage ||
        window.navigator.language
      ).replace("-", "_");
      language = language.search("en") !== -1 ? "en" : language;

      if (this.$i18n.availableLocales.indexOf(language))
        this.$i18n.locale = language;
      else this.$i18n.locale = "en";

      validatorMiddleware(this.$validator);
      this.$validator.localize(language);
    },
    getDeviceName() {
      const userAgent = navigator.userAgent;
      let os = "Unknown OS";
      let browser = "Unknown Browser";

      // Detect operating system
      if (userAgent.indexOf("Win") !== -1) os = "Windows";
      else if (userAgent.indexOf("Mac") !== -1) os = "Macintosh";
      else if (userAgent.indexOf("Linux") !== -1) os = "Linux";
      else if (userAgent.indexOf("Android") !== -1) os = "Android";
      else if (/iPhone|iPad|iPod/.test(userAgent)) os = "iOS";

      // Detect browser
      if (
        userAgent.indexOf("Chrome") !== -1 &&
        userAgent.indexOf("Edg") === -1 &&
        userAgent.indexOf("OPR") === -1
      ) {
        browser = "Chrome";
      } else if (
        userAgent.indexOf("Safari") !== -1 &&
        userAgent.indexOf("Chrome") === -1
      ) {
        browser = "Safari";
      } else if (userAgent.indexOf("Firefox") !== -1) {
        browser = "Firefox";
      } else if (
        userAgent.indexOf("OPR") !== -1 ||
        userAgent.indexOf("Opera") !== -1
      ) {
        browser = "Opera";
      } else if (userAgent.indexOf("Edg") !== -1) {
        browser = "Edge";
      } else if (
        userAgent.indexOf("MSIE") !== -1 ||
        userAgent.indexOf("Trident") !== -1
      ) {
        browser = "Internet Explorer";
      }

      // Construct device name
      return `${os}_${browser}`;
    },
    async firebaseRequestPermission() {
      try {
        if (!localStorage.getItem("accessToken")) return;

        await Notification.requestPermission();

        const deviceID = localStorage.getItem("deviceID");

        // Get the token
        const currentToken =
          localStorage.getItem("firebaseToken") || (await getToken());
        if (currentToken && currentToken !== "undefined") {
          const res = await axios.post("/g/user/notification/mobile-token", {
            acc: store.state.acc.current_acc.id,
            token: currentToken,
            type: "web",
            deviceID: deviceID !== "undefined" ? deviceID : undefined,
            deviceName: this.getDeviceName(),
          });
          const data = res.data.data;
          if (data.deviceID) {
            localStorage.setItem("firebaseToken", currentToken);
            localStorage.setItem("deviceID", data.deviceID);
          } else if (data.invalidToken) {
            localStorage.removeItem("firebaseToken");
          }
        } else {
          //console.error("No registration token available. Request permission to generate one.");
        }
      } catch (error) {
        //console.error("Error getting notification permission:", error);
      }
    },
  },
  async mounted() {
    //firebase
    if (process.env.VUE_APP_FIREBASE_ENABLED === "true")
      await this.firebaseRequestPermission();

    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    let path = this.$router.currentRoute.path;
    if (this.$store.state.acc.current_acc.id === 0) {
      if (
        this.$router.currentRoute.meta &&
        this.$router.currentRoute.meta.authRequired
      )
        this.$router
          .push({ name: "page-login", query: { to: path } })
          .catch(() => {});
    }
    if (
      !this.$store.state.user._id &&
      this.$store.state.user.accessToken &&
      this.$store.state.user.accessToken.length > 0
    )
      this.$store.dispatch("auth/logOut");
  },
  async created() {
    let dir = this.$vs.rtl ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", dir);

    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("scroll", this.handleScroll);

    // jwt
    jwt.init();
    if (localStorage.getItem("accessToken"))
      await this.$store.dispatch(
        "auth/setToken",
        localStorage.getItem("accessToken")
      );
    await this.handleRouteChange();
    if (this.locale != null) this.setLocale(this.locale.lang);
    else this.setLocale(null);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scss>
.uf-modal-inner {
  max-height: calc(100vh - 100px) !important;
  overflow: auto !important;
}
.uf-content {
  max-height: 100% !important;
}
</style>
