/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import "material-icons/iconfont/material-icons.css"; //Material Icons
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

import * as Sentry from "@sentry/vue";

// Firebase
import "./plugins/firebase";

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// API Calls
import "./http/requests";

// mock
//import "./fake-db/index.js"

// Theme Configurations
import "../themeConfig.js";

// Auth0 Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);

import { Icon } from "@iconify/vue2";
Vue.component("iconify", Icon);

// ACL
import acl from "./acl/acl";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// i18n
import i18n from "./i18n/i18n";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// Chatbot Admin Filters
import "./filters/filters";

// VeeValidate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");

import VueDictaphone from "vue-dictaphone";
Vue.use(VueDictaphone);

// Localbase
import db from "./plugins/localbase";
Vue.prototype.$db = db;

// Vue-tel-input
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

// VueExpandableImage
import VueExpandableImage from "vue-expandable-image";
Vue.use(VueExpandableImage);


import VueCodemirror from "vue-codemirror";
Vue.use(VueCodemirror);


Vue.use(VueTelInput, {
  mode: "international",
  autoFormat: true,
  defaultCountry: 55,
  autoDefaultCountry: false,
  required: true,
  invalidMsg: "Telefone inválido, por favor verifique",
  validCharactersOnly: true,
  maxlength: "1",
  inputOptions: {
    placeholder: "",
    autocomplete: "off",
  },
  dropdownOptions: {
    showDialCodeInList: true,
    showFlags: true,
  },
});

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import VueGtm from "@gtm-support/vue2-gtm";

import Gleap from "gleap";

import mautic from "mautic-tracking";
if (location.hostname === "app.duotalk.io") {
  const appVersion = require("../package.json").version;

  Sentry.init({
    Vue,
    dsn: "https://c3b8fc12fd42a8d1f50834ca49ca26f8@o4508449807466496.ingest.us.sentry.io/4508449809694720",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: [
      "app.duotalk.io",
      /^https:\/\/api.duotalk.io/,
      /^https:\/\/ws.duotalk.io/,
      /^https:\/\/wstc.duotalk.io/,
      /^https:\/\/wsai.duotalk.io/,
    ],
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    release: appVersion,
  });

  Vue.use(VueGtm, {
    id: "GTM-TSHX8LG",
    loadScript: true,
    vueRouter: router,
  });

  mautic.initialize("https://materiais.duotalk.io/mtc.js");
  var mauticTracked = false;

  var userTrackingInterval = window.setInterval(function () {
    try {
      if (localStorage.getItem("userInfo")) {
        const UserInfo = JSON.parse(localStorage.getItem("userInfo"));
        const AccInfo = JSON.parse(localStorage.getItem("activeAccInfo"));

        if (UserInfo && AccInfo && !mauticTracked) {
          mauticTracked = true;
          mautic.pageView({
            name: UserInfo.name,
            email: UserInfo.email,
            userprofile: UserInfo._id,
            account: AccInfo.id,
            accountname: AccInfo.name,
            userrole: UserInfo.userRole,
            phone: UserInfo.phone ? UserInfo.phone : null,
          });
        }

        if (
          !window.location.pathname.includes("/login") &&
          UserInfo &&
          UserInfo.gleapUserHash
        ) {
          clearInterval(userTrackingInterval);
          Gleap.initialize("0iY3DF48RxD5TCawTdWntVu3v77RHki4");
          if (AccInfo && AccInfo.id) {
            Gleap.attachCustomData({
              currentAccID: AccInfo.id,
              currentAccName: AccInfo.name,
            });
          }
          Gleap.identify(
            UserInfo._id,
            {
              name: UserInfo.name.split(" ")[0],
              email: UserInfo.email,
              companyName: AccInfo ? AccInfo.name : null,
              phone: UserInfo.phone ? UserInfo.phone : null,
              customData: {
                role: UserInfo.userRole,
              },
            },
            UserInfo.gleapUserHash
          );
        }
      }
    } catch (e) {
      //console.log("Error in gleap userTrackingInterval",e);
    }
  }, 2000);
}

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  acl,
  render: (h) => h(App),
}).$mount("#app");

Vue.config.devtools = false;
